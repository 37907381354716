(function ($) {
  $.fn.selectpicker.defaults = {
    noneSelectedText: 'Niets geselecteerd',
    noneResultsText: 'Geen resultaten gevonden voor {0}',
    countSelectedText: '{0} van {1} geselecteerd',
    maxOptionsText: ['Limiet bereikt ({n} {var} max)', 'Groep limiet bereikt ({n} {var} max)', ['items', 'item']],
    selectAllText: 'Alles selecteren',
    deselectAllText: 'Alles deselecteren',
    multipleSeparator: ', '
  };
})(jQuery);
